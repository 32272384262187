<template>
  <section class="section__container">
    <div class="section__container_background">
      <img
        max-width="100%"
        height="100%"
        src="../../assets/conteudos_violacoes_direito_background_03.png"
        alt=""
      />

      <GoBack />
    </div>
    <div class="container position--relative">
      <section class="container">
        <v-row class="d-flex justify-center flex-column align-center">
          <div class="d-flex align-self-start pt-2 pb-2">
            <Breadcrumb
              style="color: white"
              class="conteudos_tematicos_section_bc-color"
            >
              <template v-slot:breadcrumbLinks>
                <router-link
                  tag="p"
                  :to="{ name: link.href }"
                  v-for="(link, idx) in breadcrumbItems"
                  :key="idx"
                >
                  {{ link.text }}
                </router-link>
              </template>
            </Breadcrumb>
          </div>
          <v-col class="d-flex justify-center align-center">
            <img
              width="50px"
              src="../../assets/conteudos_violacoes_direito_title_engine.png"
              alt=""
            />
            <h3 class="ml-3">
              Parcialidade da mediação
            </h3>
          </v-col>
        </v-row>
      </section>
      <section class="container">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  A recente Lei de Mediação, de junho de 2015, teve como um de
                  seus objetivos regulamentar a mediação de conflitos no Brasil,
                  que é o método destinado à solução de controvérsias e
                  pacificação de conflitos sociais. No artigo 2° da Lei,
                  dedicado aos princípios da mediação, há menção ao princípio da
                  imparcialidade do mediador, que consiste na impossibilidade da
                  equipe de mediação, por meio de sua atuação, privilegiar os
                  interesses de um dos lados do conflito em detrimento do outro.
                </p>
                <p>
                  No bojo do Termo de Transação e Ajustamento de Conduta (TTAC)
                  de 2018, um dos mais emblemáticos desde o desastre–crime do
                  rompimento da barragem de Fundão, foi acordado que o pagamento
                  das indenizações seria realizado de forma individual, podendo
                  a negociação dos valores indenizatórios ser feita
                  extrajudicialmente. Após esse acordo, a Fundação Renova elege
                  a equipe de mediação de conflitos para as tratativas, às quais
                  nomeou como Fase de Negociação Extrajudicial (FNE).
                </p>
                <p>
                  Como dito, a mediação de conflitos tem como base o princípio
                  legal da imparcialidade do mediador. Contudo, nas reuniões de
                  mediação extrajudicial conduzidas pela equipe contratada pela
                  Fundação Renova a imparcialidade é altamente questionável.
                  Algumas razões observadas pelas pessoas atingidas e pela
                  equipe de Assessoria Jurídica da Cáritas MG, serão elencadas a
                  seguir, com fundamento também no marco regulatório da mediação
                  (Lei 13.140/2015):
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container">
        <v-row class="d-flex justify-center align-center mt-5">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <ul>
                  <li>
                    Como a FNE consiste em procedimento extrajudicial, caberia
                    às partes a escolha do mediador, como resguarda a própria
                    Lei de Mediação em seu artigo 4ª. No entanto, a Fundação
                    Renova faz contratações de empresas que prestam serviço de
                    mediação, de forma unilateral, sem permitir a participação
                    das atingidas e dos atingidos no processo seletivo. Logo, a
                    violação à imparcialidade da equipe de mediação que atua na
                    FNE tem início desde o momento de sua escolha.
                  </li>
                  <li>
                    As reuniões durante a FNE são conduzidas por mediadoras e
                    por mediadores que possuem sua referência de trabalho no
                    espaço da Fundação Renova. Isso porque, quando as reuniões
                    de FNE eram realizadas em formato presencial, a equipe de
                    mediação tinha como local de trabalho o mesmo espaço físico
                    utilizado pelos analistas, advogados e demais profissionais
                    da Fundação Renova. Assim, mediadores e mediadoras possuem
                    contato direto com tais profissionais, sendo possível que
                    saibam de trâmites internos da instituição.
                  </li>
                  <li>
                    As famílias atingidas com frequência relatam insegurança
                    quando, durante a FNE, percebem que a equipe de mediação
                    utiliza
                    <strong>uniformes com o emblema da Fundação Renova</strong>,
                    fato que evidencia a possível parcialidade da equipe. No
                    mesmo sentido, as atas das reuniões, apesar de redigidas
                    pela equipe de mediação, possuem estética que as vincula à
                    Fundação Renova, uma vez que também portam o emblema da
                    entidade.
                  </li>
                  <li>
                    O teor das citadas atas das reuniões da FNE, frequentemente,
                    apenas reforça as falas e os posicionamentos da própria
                    Fundação Renova, não sendo levadas a termo pela equipe de
                    mediação falas e questionamentos das pessoas atingidas e da
                    Assessoria Jurídica, mesmo quando solicitam o registro de
                    forma expressa. Dessa forma, as atas que deveriam ser um
                    instrumento de ambas as partes, passam a ser mais uma
                    ferramenta da Fundação Renova para seguir na sua missão de
                    negar indenizações pela integralidade dos danos causados às
                    pessoas atingidas pelo rompimento.
                  </li>
                </ul>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center mt-5">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  Diante disso, há fortes indícios de que a equipe de mediação
                  que participa da FNE não é imparcial, pois não atua no sentido
                  de atender aos interesses de ambas as partes, mas sim para
                  atender os interesses da Fundação Renova, sua contratante. Por
                  esse motivo, entende-se que a FNE não é meio hábil para
                  reparar, de forma integral, os danos sofridos pelas pessoas
                  atingidas desde o rompimento da barragem de Fundão.
                </p>
                <p>
                  Convida-se à leitura do
                  <a
                    target="_blank"
                    style="text-decoration: none"
                    href="https://www.google.com/url?q=http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf&sa=D&source=docs&ust=1653146093495057&usg=AOvVaw0U-VdAtCGHL5Bp8DqQcJmC"
                    class="pink--text"
                    >Parecer Técnico Jurídico</a
                  >
                  produzido pela equipe de Assessoria Jurídica da Cáritas MG, em
                  que foram relatadas, com mais detalhes, as práticas e
                  violações da Fundação Renova, no âmbito da FNE, perpetradas a
                  fim de negar a
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                    >reparação integral</router-link
                  >
                  dos danos decorrentes do rompimento da barragem de
                  Fundão<sup>1</sup>.
                </p>

                <p style="text-align: center;">
                  <strong
                    >Autoria: Equipe de Assessoria Jurídica da ATI de
                    Mariana</strong
                  >
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container pb-10 mt-4 section__mb">
        <v-row class="d-flex">
          <v-col cols="12">
            <div class="inside__card">
              <p style="margin-bottom: 0;">
                <sup>1</sup> Disponível em:
                <a
                  target="_blank"
                  class="pink--text"
                  style="text-decoration: none;"
                  href="1 http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf"
                >
                  1
                  http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf</a
                >. Acesso em 17/03/2022.
              </p>
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center pa-4 section__mb">
          <v-col cols="12" md="7" class="d-flex justify-center align-center">
            <v-btn
              :to="{ name: 'conteudos_tematicos_violacoes_indenizacao_terra' }"
              color="#EDBD43"
            >
              <strong>Conhecer "Não Indenização da terra nua"</strong></v-btn
            >
          </v-col>
        </v-row>
      </section>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Violações de Direito /",
          href: "conteudos_tematicos_violacoes_direitos",
        },

        {
          text: "Parcialidade da mediação",
          href: "conteudos_tematicos_violacoes_parcialidade_mediacao",
        },
      ],
    };
  },
  created() {},
};
</script>

<style scoped>
.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.v-btn {
  width: 80%;
  word-wrap: normal;
  white-space: nowrap;
  text-align: center;
}
.section__container {
  position: relative;
}

li {
  font-size: 14px;
}

.section__container_background {
  position: fixed;
  top: 0;
}

h3 {
  color: white;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
    display: flex;
    justify-content: center;
  }
  h3 {
    color: black;
  }
}

@media (min-width: 1400px) {
  .section__container_background {
    display: flex;
    justify-content: center;
  }
}

.position--relative {
  position: relative;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__mb {
  padding-bottom: 3em;
}
</style>
